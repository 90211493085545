// @flow
import React from 'react'
// eslint-disable-next-line
import { Redirect } from '@reach/router'
// import { Link } from 'gatsby'
// eslint-disable-next-line
// import { Container } from 'reactstrap'

// import Layout, { SEO } from '../components/Layout'
import { loggedIn } from '../lib/helper'

const IndexPage = () => {
  const redirect = !loggedIn() ? '/login' : '/app'
  return <Redirect to={redirect} noThrow />

  // return (
  //   <Layout>
  //     <SEO title="Startseite" />

  //     <Container>
  //       <h1>Willkommen</h1>

  //       <p>
  //         <Link to="/login">Zum Login</Link>
  //       </p>
  //     </Container>
  //   </Layout>
  // )
}

export default IndexPage
